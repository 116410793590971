<template>
  <v-container>
    <v-progress-circular
      indeterminate
      color="primary"
      v-if="!this.apiErrorMsg"
    ></v-progress-circular>

    <br />

    <v-alert v-if="this.apiErrorMsg" prominent type="error">
      <v-row align="center">
        <v-col class="grow">
          {{ this.apiErrorMsg }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="closeWindow()">Close Window</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <span v-if="!this.apiErrorMsg">
      <b>
        Please do not close this window, generating HRDC Invoice... <br />
        This windows will auto close after generated.
      </b>
    </span>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tpdiy/objects/globalData";

export default {
  props: ["params"],
  mixins: [hrdcFunction],
  data: () => ({
    model: new Model(),
    service: new Service(),
    apiXeroExchangeCode: new Api(),
    apiStoreXeroToken: new Api(),
    hrdcData: new HrdcData(),
    apiErrorMsg: null,
  }),
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      hrdc_tpdiy: (state) => state.hrdc_tpdiy.data,
    }),
  },
  methods: {
    getRedirectUri() {
      return `${process.env.VUE_APP_URL}/en/service/hrdc_tpdiy/callback_tpdiy`;
    },
    closeWindow() {
      window.close();
    },
  },
  created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    const apiDetails = {
      apiStoreXeroToken: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/xero/store-xero-token`,
        method: "post",
      },
      apiXeroExchangeCode: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/xero/exchange-code`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiXeroExchangeCode.setParams({
      xero_exchange_code_url: process.env.VUE_APP_XERO_EXCHANGE_CODE_URL,
      grant_type: "authorization_code",
      code: this.$route.query.code,
      redirect_uri: this.getRedirectUri(),
      basic_auth_token: this.getXeroBasicAuthorizationCode(),
    });
    this.apiXeroExchangeCode.setCallbackCompleted((response) => {
      try {
        if (!response || !response.status) {
          throw new Error("Invalid Response");
        }

        // 1. Store refresh_token in db.
        this.apiStoreXeroToken.setParams({
          valid_from: moment().format("YYYY-MM-DD"),
          valid_to: moment().add(58, "days").format("YYYY-MM-DD"),
          token_type: "refresh_token",
          token: response.data.refresh_token,
          userUuid: this.auth.uuid,
          applicationUuid: this.$route.query.state,
          subscriptionStageId:
            this.hrdcData.stagePriority.subscriptionDateScheduled,
        });
        this.apiStoreXeroToken.setCallbackCompleted(
          async (apiStoreXeroTokenRes) => {
            try {
              if (!apiStoreXeroTokenRes || !apiStoreXeroTokenRes.status) {
                throw new Error("Failed to generate refresh token");
              }

              this.loadingText = "Generating HRDC Invoice...";

              // GET application data.
              const applicationData = apiStoreXeroTokenRes.application;

              const applicationLogData = applicationData.ApplicationLog.find(
                (item) =>
                  item.stageId ==
                    this.hrdcData.stagePriority.subscriptionDateScheduled &&
                  item.status == null
              );

              const trainingStartDate = this.convertDateFormat(
                applicationLogData.startDate
              );
              const trainingEndDate = this.convertDateFormat(
                applicationLogData.endDate
              );
              const trainingDate = `${trainingStartDate} to ${trainingEndDate}`;

              // After get access token, decode the access token to get the authEventId & get the tenant id.
              const accessToken = response.data.access_token;

              const connectionResponse = await this.xeroRequestTenantId({
                url: `${
                  this.$service[this.service.key]
                }/v1/en/console/xero/get-xero-tenant-id`,
                headers: {
                  Authorization: `Bearer ${this.$store.state.auth.data.token}`,
                },
                postData: {
                  accessToken,
                },
              });

              if (!connectionResponse || !connectionResponse.status) {
                throw new Error(
                  "Unable to generate HRDC Invoice due to XERO invalid response, please contact administrator for assistance (2)"
                );
              }

              const connectionData = connectionResponse.data;
              const connection = connectionData[connectionData.length - 1];
              const tenantId = connection.tenantId;

              // GET Contacts details.
              const { ContactID } = await this.xeroCallApi({
                authorization: this.$store.state.auth.data.token,
                service_key: this.model.serviceKey,
                access_token: accessToken,
                xero_tenant_id: tenantId,
                api_type: "get_contacts",
                method: "get",
                postData: null,
              });

              // GET AccountCode.
              const { Code, TaxType } = await this.xeroCallApi({
                authorization: this.$store.state.auth.data.token,
                service_key: this.model.serviceKey,
                access_token: accessToken,
                xero_tenant_id: tenantId,
                api_type: "get_accounts",
                method: "get",
                postData: null,
              });

              // Get TaxRates
              const taxRateObj = await this.xeroCallApi({
                authorization: this.$store.state.auth.data.token,
                service_key: this.model.serviceKey,
                access_token: accessToken,
                xero_tenant_id: tenantId,
                api_type: "get_tax_rates",
                method: "get",
                postData: null,
                url_suffix: `${TaxType}`,
              });

              const [taxRate] = taxRateObj.data.TaxRates.map(
                (item) => item.EffectiveRate
              );

              // Get tracking categories.
              const trackingCatObj = await this.xeroCallApi({
                authorization: this.$store.state.auth.data.token,
                service_key: this.model.serviceKey,
                access_token: accessToken,
                xero_tenant_id: tenantId,
                api_type: "get_tracking_categories",
                method: "get",
                postData: null,
              });

              // Start to do looping based on refund type (normal/70+30)
              const LineItems = [];
              let xeroProcessCount = 0;
              const descriptionData = {
                programmeName: this.formData.programmeName,
                trainingDate,
                companyName: applicationData.oriCompanyName,
                grantApprovalNo: this.formData.grantApprovalNo,
                note: null,
              };

              if (applicationData.refundType == 1) {
                const {
                  unitAmount: requestInvoiceUnitAmount,
                  taxAmount: requestInvoiceTaxAmount,
                } = this.calculateLineAmounts(
                  applicationData.requestManualInvoiceAmount,
                  taxRate
                );

                // Normal Claim
                LineItems.push({
                  Description: this.generateHrdcInvoiceDesc(descriptionData),
                  Quantity: 1,
                  LineAmount: requestInvoiceUnitAmount,
                  TaxType,
                  AccountCode: Code,
                  TaxAmount: requestInvoiceTaxAmount,
                  Tracking: [
                    {
                      ...trackingCatObj.divisonTracking,
                    },
                    {
                      ...trackingCatObj.productTracking,
                    },
                  ],
                });
              }

              if (applicationData.refundType == 2) {
                // 70+30 Claim
                const {
                  unitAmount: requestInvoice70UnitAmount,
                  taxAmount: requestInvoice70TaxAmount,
                } = this.calculateLineAmounts(
                  applicationData.seventyPercentPrice,
                  taxRate
                );
                const {
                  unitAmount: requestInvoice30UnitAmount,
                  taxAmount: requestInvoice30TaxAmount,
                } = this.calculateLineAmounts(
                  applicationData.thirtyPercentPrice,
                  taxRate
                );

                const additionalNote30 = `RM ${this.formData.thirtyPercent} - 30 % Upfront Payment`;
                descriptionData.note = additionalNote30;
                const Description30 =
                  this.generateHrdcInvoiceDesc(descriptionData);

                descriptionData.note = `${additionalNote30}, RM ${this.formData.seventyPercent} - 70% Payment Upon Claim Approval.`;
                const Description70 =
                  this.generateHrdcInvoiceDesc(descriptionData);

                LineItems.push(
                  // 70%
                  {
                    Description: Description70,
                    Quantity: 1,
                    LineAmount: requestInvoice70UnitAmount,
                    TaxType,
                    AccountCode: Code,
                    TaxAmount: requestInvoice70TaxAmount,
                    Tracking: [
                      {
                        ...trackingCatObj.divisonTracking,
                      },
                      {
                        ...trackingCatObj.productTracking,
                      },
                    ],
                  },
                  // 30%
                  {
                    Description: Description30,
                    Quantity: 1,
                    LineAmount: requestInvoice30UnitAmount,
                    TaxType,
                    AccountCode: Code,
                    TaxAmount: requestInvoice30TaxAmount,
                    Tracking: [
                      {
                        ...trackingCatObj.divisonTracking,
                      },
                      {
                        ...trackingCatObj.productTracking,
                      },
                    ],
                  }
                );
              }

              for (const LineItem of LineItems) {
                // Construct data needed to request api to XERO.
                const invoicePostData = {
                  Invoices: [
                    {
                      Type: this.INVOICE_TYPE,
                      Contact: {
                        ContactID,
                      },
                      LineItems: [LineItem],
                      Status: this.getInvoiceStatus(),
                      DueDate: moment().format("YYYY-MM-DD"),
                    },
                  ],
                };

                // Call XERO generate invoice api.
                const generateInvoiceRes = await this.xeroCallApi({
                  authorization: this.$store.state.auth.data.token,
                  service_key: this.model.serviceKey,
                  access_token: accessToken,
                  xero_tenant_id: tenantId,
                  api_type: "post_invoices",
                  method: "post",
                  postData: invoicePostData,
                });
                const generateInvoiceResData = generateInvoiceRes.data;

                if (
                  !generateInvoiceRes ||
                  generateInvoiceRes.status !== 200 ||
                  generateInvoiceResData.Status !== "OK"
                ) {
                  throw new Error(
                    "HRDC Invoice created, but failed to upload invoice to system, please contact administrator for assistance"
                  );
                }

                // Construct GET HRDC Invoice attachments data.
                const invoiceDetails = generateInvoiceResData.Invoices.map(
                  (item) => {
                    return {
                      InvoiceID: item.InvoiceID, // A xero unique Invoice uuid
                      InvoiceNumber: item.InvoiceNumber, // e.g. YGC-XXXXXX
                      InvoiceDate: item.DueDateString,
                    };
                  }
                );

                // Call XERO GET Invoice via pdf api. (XERO API return PDF as pdf binary string type).
                const hrdcInvoiceAtchRes = await this.xeroCallApi({
                  authorization: this.$store.state.auth.data.token,
                  service_key: this.model.serviceKey,
                  access_token: accessToken,
                  xero_tenant_id: tenantId,
                  api_type: "get_invoices",
                  method: "get",
                  postData: null,
                  url_suffix: `${invoiceDetails[0].InvoiceID}`,
                  accept_header: "application/pdf",
                  config: {
                    responseType: "arraybuffer", // Response received will be an PDF type of array buffer.
                    responseEncoding: "binary", // Response encoding using binary.
                  },
                });

                // Construct data to store in azure blob storage.
                const arrayBuffer = hrdcInvoiceAtchRes.data;
                const blob = new Blob([arrayBuffer], {
                  type: "application/pdf",
                });
                const file = new File([blob], "hrdc_invoice.pdf", {
                  type: "application/pdf",
                });

                const fd = new FormData();
                fd.append("invoice", file);
                fd.append("xeroProcessCount", xeroProcessCount);
                fd.append("refundType", applicationData.refundType);
                fd.append("applicationUuid", applicationData.uuid);
                fd.append("referById", this.auth.uuid);
                fd.append("hrdcInvoiceNo", invoiceDetails[0].InvoiceNumber);
                fd.append("invoiceDate", invoiceDetails[0].InvoiceDate);
                fd.append("clientNoXero", this.HRDC_CLIENT_NO);
                fd.append(
                  "updateToStageId",
                  this.hrdcData.stagePriority.manualInvoiceToHrdc
                );
                fd.append(
                  "updateToStageName",
                  this.hrdcData.stageSlugToStageName.manualInvoiceToHrdc
                );
                fd.append(
                  "invoiceDateScheduled",
                  this.hrdcData.stagePriority.invoiceDateScheduled
                );

                // Store the HRDC Invoice PDF binary data to azure blob storage & update log.
                await this.$axios
                  .post(
                    `${
                      this.$service[this.service.key]
                    }/v1/en/console/manual_invoice/process-hrdc-flow`,
                    fd,
                    {
                      headers: {
                        Authorization: `Bearer ${this.$store.state.auth.data.token}`,
                      },
                    }
                  )
                  .then((response) => {
                    try {
                      const { status } = response.data;
                      if (status) {
                        xeroProcessCount++;

                        if (xeroProcessCount == LineItems.length) {
                          return window.close();
                        }
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  });
              }
            } catch (err) {
              console.log(err);
              this.apiErrorMsg = err.message;
              return false;
            }
          }
        );

        this.apiStoreXeroToken.fetch();
      } catch (err) {
        let errorMsg = err.message;
        if (err.response?.data?.error) {
          errorMsg = err.response.data.error;
        }

        this.apiErrorMsg = `
          XERO Error Message: ${errorMsg};
          Failed to generate HRDC Invoice, please contact admin for assistance;
          You can close this windows manually now.
        `;

        return false;
      }
    });
    this.apiXeroExchangeCode.fetch();
  },
};
</script>
